<template>
  <sheet v-if="showItems" dense depressed light outlined>
    <v-data-table
      :headers="headers"
      :items="items"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template #body="data">
        <slide-y-up-transition group tag="tbody" :delay="300">
          <!-- <debt-item-row
            v-if="!readOnly && !data.items.length"
            v-model="obPosition"
            key="invoice-debt-row-fixed"
            can-add
            @add:empty="addEmptyItemPosition"
          /> -->
          <template v-for="(item, index) in data.items">
            <!-- <pre :key="`invoice-debt-row-${uid(index)}`">
              {{ item.attributes }}
            </pre> -->
            <debt-item-row
              :key="`invoice-debt-row-${uid(index)}`"
              :can-add="index === 0 && canAdd"
              :can-delete="data.items.length > 1"
              :hide-indicator="isReceiptType"
              :value="item"
              :readonly="isCancelDebt"
              @del="deleteItemPosition(index)"
              @update="updateItemPosition($event, index)"
              @add:empty="addEmptyItemPosition"
            />
          </template>
        </slide-y-up-transition>
      </template>
    </v-data-table>
  </sheet>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";

import { SlideYUpTransition } from "vue2-transitions";
import DebtItemRow from "@/modules/invoices/components/debt/DebtItemRow.vue";
import { InvoicePosition } from "@planetadeleste/vue-mc-gw";
import { EventBus } from "@/services/event-bus";
import { get, uniqueId } from "lodash";

@Component({
  components: { SlideYUpTransition, DebtItemRow },
})
export default class DebtItems extends Mixins(DataTableMixin, InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;

  headers: DataTableHeader[] = [
    { text: "invoice", value: "invoice" },
    // { text: "invoice.position.indicator", value: "indicator" },
    { text: "invoice.original.price", value: "original_price" },
    { text: "value", value: "total_price_with_tax" },
  ];
  obPosition: InvoicePosition = new InvoicePosition();

  get items(): InvoicePosition[] {
    return this.positions;
  }

  get canAdd(): boolean {
    return !this.readOnly && !this.isReceiptType && !this.isCancelDebt;
  }

  get showItems(): boolean {
    return !this.isReceiptType || this.items.length > 0;
  }

  mounted() {
    if (this.isReceiptType) {
      this.headers.splice(1, 1);
    }

    if (!this.inView && !this.signed) {
      this.addDTActionsHeader();
    }

    this.mapDTHeaders();
    this.resetPositions();

    if (this.canAdd) {
      this.addEmptyItemPosition();
    }
  }

  created() {
    EventBus.on("invoice.customer.changed", this.resetInvoiceItems);
  }

  beforeDestroy() {
    EventBus.off("invoice.customer.changed", this.resetInvoiceItems);
  }

  resetInvoiceItems() {
    if (this.signed) {
      return;
    }

    this.resetPositions();
    this.resetReferences();

    if (this.canAdd) {
      this.addEmptyItemPosition();
    }
  }

  uid(iValue: number) {
    const obPosition = get(this.items, iValue);
    return obPosition && obPosition.get("idx")
      ? obPosition.get("idx")
      : uniqueId();
  }
}
</script>
